<template>
  <div class="page-news">
    <list-page
      ref="listPageRef"
      inline
      label-width="80px"
      :model="model"
      :fields="fields"
      @submit="submit"
      :columns="columns"
      :fetch="fetch"
      :headerCellStyle="{ background: '#f9fafc' }"
      :submitter="{
        props: {
          style: {
            display: 'flex',
            justifyContent: 'flex-end'
          }
        }
      }"
    >
      <template #addon>
        <div style="margin-bottom: 16px">
          <el-button type="primary" @click="add">添加新闻</el-button>
        </div>
      </template>
    </list-page>
  </div>
</template>

<script>
import * as ConstantRouter from '@/constant/router'

export default {
  data() {
    return {
      restaurants: [],
      model: {
        title: '',
        articleType: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '标题',
            prop: 'title'
          }
        },
        {
          type: 'select',
          formItemProps: {
            label: '文章分类',
            prop: 'articleType'
          },
          children: [
            {
              value: '',
              label: '全部'
            },
            {
              value: '1',
              label: '新闻'
            },
            {
              value: '2',
              label: '通知'
            },
            {
              value: '3',
              label: '往届作品'
            },
            {
              value: '4',
              label: '大赛专访'
            },
            {
              value: '5',
              label: '赛道简介'
            }
          ]
        }
      ],
      columns: [
        {
          label: '标题(中文)',
          prop: 'title',
          render(_, text) {
            return text || '-'
          }
        },
        {
          label: '标题(英文)',
          prop: 'titleEn',
          render(_, text) {
            return text || '-'
          }
        },
        {
          label: '文章分类',
          prop: 'articleType',
          render(_, text) {
            if (text === 1) return '新闻'
            if (text === 2) return '通知'
            if (text === 3) return '往届作品'
            if (text === 4) return '大赛专访'
            if (text === 5) return '赛道简介'
            return '-'
          }
        },
        {
          label: '缩略图(中文)',
          prop: 'showImg',
          render(_, val) {
            if (!val) return '-'
            return (
              <el-image
                style="width: 100px; height: 100px"
                src={val}
                preview-src-list={[val]}
              ></el-image>
            )
          }
        },
        {
          label: '缩略图(英文)',
          prop: 'showImgEn',
          render(_, val) {
            if (!val) return '-'
            return (
              <el-image
                style="width: 100px; height: 100px"
                src={val}
                preview-src-list={[val]}
              ></el-image>
            )
          }
        },
        {
          label: '发布日期',
          prop: 'articleDate',
          render(_, text) {
            return text || '-'
          }
        },
        {
          label: '操作',
          render: (_, record) => {
            return (
              <div>
                <el-button type="text" onClick={() => this.toDetail(record)}>
                  详情
                </el-button>
                <el-button type="text" onClick={() => this.toUpdate(record)}>
                  修改
                </el-button>
                <el-popconfirm
                  title="确认删除吗？"
                  onConfirm={() => this.toDelete(record)}
                >
                  <el-button
                    style="margin-left: 10px"
                    slot="reference"
                    type="text"
                  >
                    删除
                  </el-button>
                </el-popconfirm>
              </div>
            )
          }
        }
      ]
    }
  },
  methods: {
    submit() {
      console.log(this.model)
    },
    fetch({ currentPage, pageSize, ...req }) {
      return this.$serve.jsArticle
        .getPageList({
          data: {
            pageIndex: currentPage,
            pageSize: pageSize,
            ...req
          }
        })
        .then(([, { records, ...res }]) => {
          return {
            ...res,
            list: records
          }
        })
    },
    add() {
      this.$router.push({
        name: ConstantRouter.DASHBOARD_NEWS_ADD
      })
    },
    toDetail(record) {
      this.$router.push({
        name: ConstantRouter.DASHBOARD_NEWS_DETAIL,
        params: {
          id: record.id
        }
      })
    },
    toUpdate(record) {
      this.$router.push({
        name: ConstantRouter.DASHBOARD_NEWS_UPDATE,
        params: {
          id: record.id
        }
      })
    },
    async toDelete(record) {
      const [err] = await this.$serve.jsArticle.delete({
        queryId: record.id
      })
      if (err) return
      this.$message.success('新闻删除成功')
      this.$refs.listPageRef.fetchData()
    }
  }
}
</script>
