var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-news"},[_c('list-page',{ref:"listPageRef",attrs:{"inline":"","label-width":"80px","model":_vm.model,"fields":_vm.fields,"columns":_vm.columns,"fetch":_vm.fetch,"headerCellStyle":{ background: '#f9fafc' },"submitter":{
      props: {
        style: {
          display: 'flex',
          justifyContent: 'flex-end'
        }
      }
    }},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"addon",fn:function(){return [_c('div',{staticStyle:{"margin-bottom":"16px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("添加新闻")])],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }